/* eslint-disable max-lines-per-function */

import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { ReactNode } from 'react';
import NavigationBar from './NavigationBar';
import { NavigationBase } from './NavigationBase';
import NavigationButton from './NavigationButton';
import NavigationListAccount, { NavigationListAccountProps } from './NavigationListAccount';
import NavigationListMain, { NavigationListMainProps } from './NavigationListMain';
import { CUSTOM_BREAK_POINTS, SHARED_VALUES } from './constants';

export type NavigationProps = NavigationListAccountProps &
    NavigationListMainProps & {
        children?: ReactNode;
        homeHref: string;
        logoImageSrc: string;
        openDropdownMobile: boolean;
        sx?: SxProps<Theme>;
        toggleOpenDropdownMobile: () => void;
    };

export const Navigation = ({
    adminHref,
    agenciesHref,
    agencyHref,
    brandHref,
    buildAndScheduleHref,
    children,
    clientLinks,
    connectHref,
    homeHref,
    icons,
    learnHref,
    locationDetailsHref,
    logoImageSrc,
    notificationsHref,
    openDropdownMobile,
    openDropdownUser,
    overviewHref,
    planHref,
    profileHref,
    reportHref,
    showAdmin,
    showAgencies,
    showAgency,
    showBrand,
    showBuildAndSchedule,
    showLearn,
    showPlan,
    showReport,
    showSpecialRequests,
    signOutHref,
    specialRequestsHref,
    sx,
    t,
    toggleOpenDropdownMobile,
    toggleOpenDropdownUser,
    userDropdownRef,
    userImageSrc,
    userInitials,
    userName,
    ...props
}: NavigationProps): JSX.Element => (
    <NavigationBase
        {...props}
        homeHref={homeHref}
        isResponsive
        navContent={
            <>
                <NavigationButton
                    sx={(theme: Theme) => ({
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        marginBottom: '8px',
                        marginTop: '11px',
                        minHeight: '34px',
                        [theme.breakpoints.up(CUSTOM_BREAK_POINTS['1-md'])]: {
                            display: 'none',
                        },
                        width: 'auto',
                    })}
                    onClick={toggleOpenDropdownMobile}
                >
                    <span style={visuallyHidden}>{t('Toggle navigation')}</span>
                    <NavigationBar />
                    <NavigationBar />
                </NavigationButton>
                <Box
                    component="nav"
                    sx={[
                        (theme: Theme) => ({
                            backgroundColor: 'white',
                            display: 'grid',
                            gridTemplateColumns: '1fr',
                            gridTemplateRows: '0fr',
                            justifyContent: 'space-between',
                            marginLeft: `-${SHARED_VALUES.gutter}`,
                            marginRight: `-${SHARED_VALUES.gutter}`,
                            position: 'absolute',
                            [theme.breakpoints.up(CUSTOM_BREAK_POINTS['1-md'])]: {
                                boxShadow: 'none',
                                display: 'flex',
                                marginLeft: 0,
                                marginRight: 0,
                                position: 'relative',
                                top: 0,
                            },
                            top: SHARED_VALUES.minHeight,
                            transition: `grid-template-rows ${SHARED_VALUES.transitionDuration} ease-out`,
                            width: '100%',
                        }),
                        openDropdownMobile && {
                            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
                            gridTemplateRows: '1fr',
                        },
                    ]}
                >
                    <Box
                        sx={[
                            (theme: Theme) => ({
                                borderTopColor: 'transparent',
                                borderTopStyle: 'solid',
                                borderTopWidth: 1,
                                display: 'flex',
                                overflowX: 'visible',
                                overflowY: 'auto',
                                [theme.breakpoints.up(CUSTOM_BREAK_POINTS['0-sm'])]: {
                                    marginBottom: 0,
                                    marginLeft: SHARED_VALUES.gutter,
                                    marginRight: SHARED_VALUES.gutter,
                                    marginTop: 0,
                                    width: `calc(100% - ${SHARED_VALUES.gutter} - ${SHARED_VALUES.gutter})`,
                                },
                                [theme.breakpoints.up(CUSTOM_BREAK_POINTS['1-md'])]: {
                                    marginLeft: 0,
                                    marginRight: 0,
                                    overflowY: 'unset',
                                    width: '100%',
                                },
                                transition: `border-top-color ${SHARED_VALUES.transitionDuration} ease-out`,
                                width: '100%',
                            }),
                            openDropdownMobile &&
                                ((theme: Theme) => ({
                                    borderTopColor: 'black',
                                    [theme.breakpoints.up(CUSTOM_BREAK_POINTS['1-md'])]: {
                                        borderTopColor: 'transparent',
                                        transition: 'none',
                                    },
                                })),
                        ]}
                    >
                        <Box
                            sx={[
                                (theme: Theme) => ({
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '300px',
                                    [theme.breakpoints.up(CUSTOM_BREAK_POINTS['1-md'])]: {
                                        flexDirection: 'row',
                                        height: 'unset',
                                    },
                                    width: '100%',
                                }),
                                openDropdownUser &&
                                    ((theme: Theme) => ({
                                        [theme.breakpoints.down(CUSTOM_BREAK_POINTS['1-md'])]: {
                                            height: '320px',
                                        },
                                    })),
                            ]}
                        >
                            <NavigationListMain
                                brandHref={brandHref}
                                buildAndScheduleHref={buildAndScheduleHref}
                                learnHref={learnHref}
                                planHref={planHref}
                                reportHref={reportHref}
                                showBrand={showBrand}
                                showBuildAndSchedule={showBuildAndSchedule}
                                showLearn={showLearn}
                                showPlan={showPlan}
                                showReport={showReport}
                                showSpecialRequests={showSpecialRequests}
                                specialRequestsHref={specialRequestsHref}
                                t={t}
                            />
                            {/* The below is a filler component so the rest of the nav is aligned right */}
                            <div style={{ flex: 'auto' }} />
                            {children}
                            <NavigationListAccount
                                adminHref={adminHref}
                                agenciesHref={agenciesHref}
                                agencyHref={agencyHref}
                                clientLinks={clientLinks}
                                connectHref={connectHref}
                                homeHref={homeHref}
                                icons={icons}
                                locationDetailsHref={locationDetailsHref}
                                notificationsHref={notificationsHref}
                                openDropdownUser={openDropdownUser}
                                overviewHref={overviewHref}
                                profileHref={profileHref}
                                showAdmin={showAdmin}
                                showAgencies={showAgencies}
                                showAgency={showAgency}
                                showPlan={showPlan}
                                signOutHref={signOutHref}
                                t={t}
                                toggleOpenDropdownUser={toggleOpenDropdownUser}
                                userDropdownRef={userDropdownRef}
                                userImageSrc={userImageSrc}
                                userInitials={userInitials}
                                userName={userName}
                            />
                        </Box>
                    </Box>
                </Box>
            </>
        }
    />
);

Navigation.displayName = 'Navigation';
