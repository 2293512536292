import { extendSx } from '@deltasierra/react/theme';
import { Translations } from '@deltasierra/translations/react';
import { SxProps, Theme } from '@mui/material/styles';
import { RefObject } from 'react';
import { CUSTOM_BREAK_POINTS, SHARED_VALUES } from './constants';
import NavigationList from './NavigationList';
import NavigationListAccountDropdownItem from './NavigationListAccountDropdownItem';
import NavigationListAccountDropdownSeparator from './NavigationListAccountDropdownSeparator';

export interface ClientExternalLink {
    clientId: number;
    title: string;
    url: string;
    order: number;
}

export type NavigationListAccountDropdownProps = {
    adminHref: string;
    agenciesHref: string;
    agencyHref: string;
    clientLinks: Array<Pick<ClientExternalLink, 'title' | 'url'>>;
    connectHref: string;
    homeHref: string;
    icons: {
        admin: string;
        agencies: string;
        agency: string;
        clientLinks: string;
        connect: string;
        home: string;
        locationDetails: string;
        notifications: string;
        overview: string;
        profile: string;
        signOut: string;
    };
    locationDetailsHref: string;
    overviewHref: string;
    profileHref: string;
    showAdmin: boolean;
    showAgencies: boolean;
    showAgency: boolean;
    showPlan: boolean;
    signOutHref: string;
    sx?: SxProps<Theme>;
    t: Translations<'Navigation'>;
    userDropdownRef: RefObject<HTMLElement>;
};

export default function NavigationListAccountDropdown({
    adminHref,
    agenciesHref,
    agencyHref,
    clientLinks,
    connectHref,
    homeHref,
    icons,
    locationDetailsHref,
    overviewHref,
    profileHref,
    showAdmin,
    showAgencies,
    showAgency,
    showPlan,
    signOutHref,
    sx,
    t,
    userDropdownRef,
    ...props
}: NavigationListAccountDropdownProps): JSX.Element {
    return (
        <NavigationList
            {...props}
            ref={userDropdownRef}
            sx={extendSx(
                (theme: Theme) => ({
                    flexDirection: 'column',
                    fontSize: '14px',
                    listStyle: 'none',
                    marginBottom: '0',
                    marginLeft: '0',
                    marginRight: '0',
                    minWidth: '175px',
                    paddingBottom: '5px',
                    paddingLeft: 0,
                    paddingRight: 0,
                    [theme.breakpoints.up(CUSTOM_BREAK_POINTS['1-md'])]: {
                        backgroundColor: 'white',
                        borderColor: 'grey.400',
                        borderRadius: '4px',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        boxShadow: '0 6px 12px rgba(0,0,0,.175)',
                        flexDirection: 'column',
                        left: 'auto',
                        marginTop: '2px',
                        paddingTop: '5px',
                        position: 'absolute',
                        right: `calc(${SHARED_VALUES.gutter} * -1)`,
                        top: '100%',
                    },
                    zIndex: 'app-bar',
                }),
                sx,
            )}
        >
            {showAdmin && (
                <>
                    <NavigationListAccountDropdownItem href={homeHref} src={icons.home} text={t('app-web')} />
                    <NavigationListAccountDropdownItem href={adminHref} src={icons.admin} text={t('Admin')} />
                </>
            )}
            {showPlan && (
                <NavigationListAccountDropdownItem href={overviewHref} src={icons.overview} text={t('Overview')} />
            )}
            {(showAdmin || showPlan) && <NavigationListAccountDropdownSeparator />}
            <NavigationListAccountDropdownItem href={connectHref} src={icons.connect} text={t('Connect')} />
            {showAgencies && (
                <NavigationListAccountDropdownItem href={agenciesHref} src={icons.agencies} text={t('Agencies')} />
            )}
            {showAgency && (
                <NavigationListAccountDropdownItem href={agencyHref} src={icons.agency} text={t('Agency')} />
            )}
            <NavigationListAccountDropdownItem
                href={locationDetailsHref}
                src={icons.locationDetails}
                text={t('Location Details')}
            />
            <NavigationListAccountDropdownItem href={profileHref} src={icons.profile} text={t('Profile')} />
            {clientLinks.length > 0 && (
                <>
                    <NavigationListAccountDropdownSeparator />
                    {clientLinks.map(({ title, url }) => (
                        <NavigationListAccountDropdownItem
                            href={url}
                            key={url}
                            rel="noreferrer"
                            src={icons.clientLinks}
                            target="_blank"
                            text={title}
                        />
                    ))}
                </>
            )}
            <NavigationListAccountDropdownSeparator />
            <NavigationListAccountDropdownItem href={signOutHref} src={icons.signOut} text={t('Sign Out')} />
        </NavigationList>
    );
}

NavigationListAccountDropdown.displayName = 'NavigationListAccountDropdown';
