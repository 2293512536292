import { AppWebRouterType, AvailableRoutes } from '@deltasierra/frontend/routing/app-web';
import { QueryParameter } from '@deltasierra/frontend/routing/core';
import { useUnifiedFeatureFlags } from '@deltasierra/react/hooks/feature-flags';
import { Translations } from '@deltasierra/translations/react';
import { usePathname } from 'next/navigation';
import { useCallback, useMemo } from 'react';
import { NavigationViewType } from '../../enums';
import { Navigation, NavigationItem } from '../../types';

const MAX_BRANDS_TO_SHOW_ITEMS = 2;

type UseLeftNavigationLeftProps = {
    appWebRouter: AppWebRouterType;
    clientId: string | null;
    currentViewType: NavigationViewType;
    locationId: string | null;
    t: Translations<'Navigation'>;
    userBrandsQuantity: number;
};

// eslint-disable-next-line max-lines-per-function
export function useLeftNavigationLeft({
    appWebRouter,
    clientId,
    currentViewType,
    locationId,
    t,
    userBrandsQuantity,
}: UseLeftNavigationLeftProps): { navigation: Navigation } {
    const currentRoutePathName = usePathname();
    const { enableNewGalleryPage } = useUnifiedFeatureFlags({
        clientId,
        featureFlags: ['enableNewGalleryPage'],
        locationId,
    });

    const getNavigationLink = useCallback(
        ({
            newAppLink,
            oldApp,
            params,
        }: {
            newAppLink?: string;
            oldApp?: {
                route: AvailableRoutes;
                extra?: string;
            };
            params?: { clientId?: string | null; locationId?: string | null };
        }): string => {
            if (oldApp?.route) {
                return `${appWebRouter.get(oldApp.route)}${oldApp.extra ?? ''}`;
            }
            const paramsBuilder = new URLSearchParams();
            if (params?.clientId) {
                paramsBuilder.append(QueryParameter.ClientId, params.clientId);
            }
            if (params?.locationId) {
                paramsBuilder.append(QueryParameter.LocationId, params.locationId);
            }

            return `${newAppLink}/?${paramsBuilder.toString()}`;
        },
        [appWebRouter],
    );

    const navigation: Navigation = useMemo(
        // eslint-disable-next-line max-lines-per-function, max-statements
        () => {
            const learn: NavigationItem = {
                icon: 'GraduationHat02',
                id: 'Learn',
                navigationUrl: getNavigationLink({ oldApp: { route: '/trythis' } }),
                title: t('Learn'),
            };
            const calendar: NavigationItem = {
                icon: 'CalendarDate',
                id: 'Calendar',
                navigationUrl: getNavigationLink({ oldApp: { route: '/planner' } }),
                title: t('Calendar'),
            };
            const scheduled: NavigationItem = {
                icon: 'Send01',
                id: 'Scheduled',
                navigationUrl: getNavigationLink({ oldApp: { route: '/scheduled' } }),
                title: t('Scheduled'),
            };
            const templates: NavigationItem = {
                icon: 'FileShield03',
                id: 'Templates',
                isDisabled: !clientId || !locationId,
                navigationUrl: enableNewGalleryPage
                    ? getNavigationLink({ newAppLink: '/templates', params: { clientId, locationId } })
                    : getNavigationLink({ oldApp: { route: '/builderTemplateGallery' } }),
                title: t('Templates'),
            };
            const assets: NavigationItem = {
                icon: 'Image03',
                id: 'Assets',
                navigationUrl: getNavigationLink({ oldApp: { route: '/assets/view' } }),
                title: t('Assets'),
            };
            const performance: NavigationItem = {
                icon: 'PieChart03',
                id: 'Performance',
                isDisabled: !clientId,
                navigationUrl: getNavigationLink({
                    oldApp: { extra: `/location/${locationId}/overview`, route: '/reports' },
                }),
                title: t('Performance'),
            };
            const specialRequests: NavigationItem = {
                icon: 'Ticket01',
                id: 'Special requests',
                navigationUrl: getNavigationLink({ oldApp: { route: '/specialRequests' } }),
                title: t('Special Requests'),
            };
            const connections: NavigationItem = {
                icon: 'Link04',
                id: 'Connect',
                navigationUrl: getNavigationLink({ oldApp: { route: '/connect' } }),
                title: t('Connections'),
            };
            const locationDetails: NavigationItem = {
                icon: 'MarkerPin01',
                id: 'Location Details',
                navigationUrl: getNavigationLink({ oldApp: { route: '/locationDetails' } }),
                title: t('Location Details'),
            };
            const brands: NavigationItem = {
                icon: 'Passport',
                id: 'Brands',
                navigationUrl: getNavigationLink({ oldApp: { extra: '/agency/brands', route: '/brand' } }),
                title: t('Brands'),
            };
            const overview: NavigationItem = {
                icon: 'Compass03',
                id: 'Overview',
                navigationUrl: getNavigationLink({
                    oldApp: { extra: `/client/${clientId}/engagement/overview`, route: '/brand' },
                }),
                title: t('Overview'),
            };
            const regionGroupActivity: NavigationItem = {
                icon: 'Map02',
                id: 'Region/Group Activity',
                navigationUrl: getNavigationLink({
                    oldApp: { extra: `/client/${clientId}/engagement/region-group-activity`, route: '/brand' },
                }),
                title: t('Region/Group Activity'),
            };
            const locationActivity: NavigationItem = {
                icon: 'BarChartSquare03',
                id: 'Location Activity',
                navigationUrl: getNavigationLink({
                    oldApp: { extra: `/client/${clientId}/engagement/location-activity`, route: '/brand' },
                }),
                title: t('Location Activity'),
            };
            const templateUse: NavigationItem = {
                icon: 'FileCheck03',
                id: 'Template Use',
                navigationUrl: getNavigationLink({
                    oldApp: { extra: `/client/${clientId}/engagement/template-use`, route: '/brand' },
                }),
                title: t('Template Use'),
            };
            const send: NavigationItem = {
                icon: 'Announcement01',
                id: 'Send',
                navigationUrl: getNavigationLink({ oldApp: { extra: '/communicate/send', route: '/brand' } }),
                title: t('Send'),
            };
            const report: NavigationItem = {
                icon: 'FileCheck02',
                id: 'Report',
                navigationUrl: getNavigationLink({ oldApp: { extra: '/communicate/send-report', route: '/brand' } }),
                title: t('Report'),
            };
            const locations: NavigationItem = {
                icon: 'MarkerPin06',
                id: 'Locations',
                navigationUrl: getNavigationLink({
                    oldApp: { extra: `/client/${clientId}/manage/locations/locations`, route: '/brand' },
                }),
                title: t('Locations'),
            };
            const regionsGroups: NavigationItem = {
                icon: 'Globe04',
                id: 'Regions/Groups',
                navigationUrl: getNavigationLink({
                    oldApp: { extra: `/client/${clientId}/manage/regions-groups/regions-groups`, route: '/brand' },
                }),
                title: t('Regions/Groups'),
            };
            const locationUsers: NavigationItem = {
                icon: 'Users01',
                id: 'Location Users',
                navigationUrl: getNavigationLink({
                    oldApp: { extra: `/client/${clientId}/manage/users/users`, route: '/brand' },
                }),
                title: t('Location Users'),
            };
            const platforms: NavigationItem = {
                icon: 'Link05',
                id: 'Platforms',
                navigationUrl: getNavigationLink({
                    oldApp: { extra: `/client/${clientId}/connections/platforms`, route: '/brand' },
                }),
                title: t('Platforms'),
            };
            const clients: NavigationItem = {
                icon: 'Globe04',
                id: 'Clients',
                navigationUrl: getNavigationLink({ oldApp: { route: '/agency/clients' } }),
                title: t('Clients'),
            };
            const learnMenu: NavigationItem = {
                icon: 'GraduationHat02',
                id: 'Learn Menu',
                navigationUrl: getNavigationLink({
                    oldApp: { extra: `/client/${clientId}/manage/learn-menu`, route: '/brand' },
                }),
                title: t('Learn Menu'),
            };
            const calendarActivities: NavigationItem = {
                icon: 'Beaker02',
                id: 'Calendar Activities',
                navigationUrl: getNavigationLink({
                    oldApp: { extra: `/client/${clientId}/manage/activities`, route: '/brand' },
                }),
                title: t('Calendar Activities'),
            };
            const templateCategories: NavigationItem = {
                icon: 'Bookmark',
                id: 'Template Categories',
                navigationUrl: getNavigationLink({
                    oldApp: { extra: `/client/${clientId}/manage/client-categories`, route: '/brand' },
                }),
                title: t('Template Categories'),
            };
            const hashtagSets: NavigationItem = {
                icon: 'Hash01',
                id: 'Hashtag Sets',
                navigationUrl: getNavigationLink({
                    oldApp: { extra: `/client/${clientId}/manage/hashtags`, route: '/brand' },
                }),
                title: t('Hashtag Sets'),
            };
            const styles: NavigationItem = {
                icon: 'Brush03',
                id: 'Styles',
                isSelected: true,
                navigationUrl: getNavigationLink({ oldApp: { extra: `/client/${clientId}/style`, route: '/brand' } }),
                subItems: [
                    {
                        id: 'Fonts',
                        navigationUrl: getNavigationLink({
                            oldApp: { extra: `/client/${clientId}/style/fonts`, route: '/brand' },
                        }),
                        title: t('Fonts'),
                    },
                    {
                        id: 'Colors',
                        navigationUrl: getNavigationLink({
                            oldApp: { extra: `/client/${clientId}/style/colors`, route: '/brand' },
                        }),
                        title: t('Colors'),
                    },
                    {
                        id: 'Style Guide',
                        navigationUrl: getNavigationLink({
                            oldApp: { extra: `/client/${clientId}/style/style-guide`, route: '/brand' },
                        }),
                        title: t('Style Guide'),
                    },
                ],
                title: t('Styles'),
            };
            const locationMenu: Navigation = [
                {
                    id: 'SECTION 1',
                    isMaintainedOnClose: true,
                    items: [
                        { ...learn },
                        { ...calendar },
                        { ...scheduled },
                        { ...templates },
                        { ...assets },
                        { ...performance },
                        { ...specialRequests },
                        { ...connections },
                        { ...locationDetails },
                    ],
                },
            ];
            const brandMenuSectionOneItems = [
                { ...overview },
                { ...locationActivity },
                { ...templateUse },
                { ...regionGroupActivity },
            ];
            if (userBrandsQuantity >= MAX_BRANDS_TO_SHOW_ITEMS) {
                brandMenuSectionOneItems.unshift({ ...brands });
            }
            const brandMenu: Navigation = [
                {
                    id: 'SECTION 1',
                    isMaintainedOnClose: true,
                    items: brandMenuSectionOneItems,
                },
                {
                    hasDividerAbove: true,
                    headerTitle: t('Communicate'),
                    id: 'SECTION 2',
                    isMaintainedOnClose: false,
                    items: [{ ...send }, { ...report }],
                },
                {
                    hasDividerAbove: true,
                    headerTitle: t('Locations'),
                    id: 'SECTION 3',
                    isMaintainedOnClose: false,
                    items: [{ ...specialRequests }, { ...locations }, { ...regionsGroups }, { ...locationUsers }],
                },
                {
                    hasDividerAbove: true,
                    headerTitle: t('Integrations'),
                    id: 'SECTION 4',
                    isMaintainedOnClose: false,
                    items: [{ ...platforms }, { ...connections }],
                },
                {
                    hasDividerAbove: true,
                    headerTitle: t('Brand Settings'),
                    id: 'SECTION 5',
                    isMaintainedOnClose: false,
                    items: [
                        { ...clients },
                        { ...learnMenu },
                        { ...calendarActivities },
                        { ...templateCategories },
                        { ...hashtagSets },
                        { ...styles },
                    ],
                },
            ];

            return (currentViewType === NavigationViewType.location ? locationMenu : brandMenu).map(section => ({
                ...section,
                items: section.items.map(item => ({
                    ...item,
                    isSelected: item.navigationUrl.startsWith(currentRoutePathName),
                    subitems: item.subItems?.map(subItem => ({
                        ...subItem,
                        isSelected: subItem.navigationUrl.startsWith(currentRoutePathName),
                    })),
                })),
            }));
        },
        [
            clientId,
            currentRoutePathName,
            currentViewType,
            enableNewGalleryPage,
            getNavigationLink,
            locationId,
            t,
            userBrandsQuantity,
        ],
    );

    return { navigation };
}
