import { Box, Container, Theme } from '@deltasierra/react/components/core';
import Image from 'next/image';
import Link from 'next/link';
import { ReactNode } from 'react';
import { CUSTOM_BREAK_POINTS, SHARED_VALUES } from './constants';

type NavigationProps = {
    homeHref: string;
    isResponsive: boolean;
    navContent: ReactNode;
};

export const NavigationBase = ({ homeHref, isResponsive, navContent, ...restProps }: NavigationProps): JSX.Element => (
    <>
        <Box
            {...restProps}
            aria-label="main navigation"
            role="navigation"
            sx={{
                backgroundColor: 'white',
                boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
                color: 'black',
                display: 'flex',
                fontSize: '16px',
                fontStretch: 'normal',
                fontWeight: '400',
                justifyContent: 'center',
                left: 0,
                letterSpacing: 'normal',
                lineHeight: '1.25',
                paddingTop: 'env(safe-area-inset-top)',
                position: 'fixed',
                right: 0,
                top: 0,
                whiteSpace: 'nowrap',
                zIndex: 'drawer',
            }}
        >
            <Container
                sx={(theme: Theme) => ({
                    alignItems: 'center',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexWrap: isResponsive ? 'wrap' : 'nowrap',
                    gap: '20px',
                    justifyContent: isResponsive ? 'space-between' : 'flex-start',
                    minHeight: SHARED_VALUES.minHeight,
                    position: 'relative',
                    [theme.breakpoints.up(CUSTOM_BREAK_POINTS['1-md'])]: {
                        flexWrap: 'nowrap',
                        justifyContent: 'flex-start',
                    },
                    width: '100%',
                })}
            >
                <Box
                    component={Link}
                    href={homeHref}
                    sx={{
                        marginLeft: `-${SHARED_VALUES.gutter}`,
                        marginTop: '2px',
                        paddingBottom: '11px',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        paddingTop: '15px',
                    }}
                >
                    <Image
                        alt="Digital Stack"
                        height={41}
                        priority
                        src={'/ds_horizontal_logo_rgb.svg'}
                        style={{
                            maxHeight: 30,
                            width: 'auto',
                        }}
                        width={300}
                    />
                </Box>
                {navContent}
            </Container>
        </Box>
        <Box sx={{ paddingTop: SHARED_VALUES.minHeight }} />
    </>
);

NavigationBase.displayName = 'NavigationBase';
